String.prototype.format = format;

// eslint-disable-next-line id-blacklist
interface String {
    format: typeof format;
}

function format(...args: any) {
    let a = this;
    for (const k in Object.keys(args)) {
      if (arguments.hasOwnProperty(k)) {
        a = a.replace(new RegExp('\\{' + k + '\\}', 'g'), arguments[k]);
      }
    }
    a = a.replace(new RegExp('\\{\\d\\}', 'g'), '');
    return a;
}
